import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { image, container, mainContainer, wave, shapeFill } from "./chantier.module.css";

function Chantier() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulImagesChantier {
        nodes {
          imagechantier {
            gatsbyImageData(resizingBehavior: FILL, placeholder: BLURRED)
            id
          }
          textImageChantier
        }
      }
    }
  `);

  return (
    <div id="chantier" className={mainContainer}>
      <h1 className="left">
        {data.allContentfulImagesChantier.nodes[0].textImageChantier}
      </h1>
      <div className={container}>
        {data?.allContentfulImagesChantier?.nodes[0].imagechantier.map(
          ({ gatsbyImageData, id }) => {
            return (
              <div key={id}>
                <GatsbyImage
                  image={gatsbyImageData}
                  alt={`chantier`}
                  className={image}
                />
              </div>
            );
          }
        )}
      </div>
      <div className={wave}>
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className={shapeFill}
          ></path>
        </svg>
      </div>
    </div>
  );
}

export default Chantier;
